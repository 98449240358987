
//公共库
import { Message } from "element-ui";
import { Component, Vue } from "vue-property-decorator";

//本地引入
import GFunc from "@/utils/gFunc";
import Pagination from "@/comp/Pagination/index.vue";
import { customerTagUserList, saveCustomerTagUser, removeCustomerTagUser, customerTagList } from "@/api/request/customer";

//组件
@Component({
  name: "CustomerTagUser",
  components: {
    Pagination,
  },
})

//界面函数
export default class extends Vue {
  //定义变量
  private list: any[] = [];
  private total: number = 0;
  private customerTagList: any[] = [];
  private listLoading: boolean = false;

  //列表请求参数
  private listQuery: any = {
    //页面数据
    page: 1,
    page_size: 20,

    //临时数据
    tag_id_str: "", //标签ID
    user_id_str: "", //用户ID

    //请求数据
    tag_id: 0, //标签ID
    user_id: 0, //用户ID
    tag_name: "", //标签名称
    nick_name: "", //用户昵称
  };

  //创建
  created() {
    //获取标签列表
    this.getCustomerTagList();

    //获取列表
    this.getList();
  }

  //处理添加
  private handleAdd(): void {
    //数据赋值
    this.addForm = {
      //请求数据
      tag_id: 0, //客服标签ID
      user_ids: [], //用户ID列表
    };
    this.tags = [];

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "add";
  }

  //处理添加
  private handleDel(): void {
    //数据赋值
    this.addForm = {
      //请求数据
      tag_id: 0, //客服标签ID
      user_ids: [], //用户ID列表
    };
    this.tags = [];

    //显示界面
    this.dialogVisible = true;
    this.dialogStatus = "delete";
  }

  //获取列表
  private async getList() {
    //显示等待
    this.listLoading = true;

    //数据赋值
    this.listQuery.tag_id = Number(this.listQuery.tag_id_str);
    this.listQuery.user_id = Number(this.listQuery.user_id_str);

    //获取数据
    const { data } = await customerTagUserList(this.listQuery);

    //数据赋值
    this.list = data.list;
    this.total = data.total;

    //隐藏等待
    setTimeout(() => {
      this.listLoading = false;
    }, 0.5 * 1000);
  }

  //处理查询
  private handleFilter(): void {
    //数据赋值
    this.listQuery.page = 1;

    //获取列表
    this.getList();
  }

  //获取标签列表
  private async getCustomerTagList() {
    //显示等待
    this.listLoading = true;

    //获取数据
    const { data } = await customerTagList({ page: 1, page_size: GFunc.getListRowLimit() });

    //数据赋值
    this.customerTagList = data.list;
    this.customerTagList.unshift({ id: 0, name: "请选择标签" });
  }

  //处理删除
  private handleDelete(row: any): void {
    //删除消息
    this.$confirm("确定删除客服用户 ?", "警告", {
      confirmButtonText: this.$t("permission.confirm") as string,
      cancelButtonText: this.$t("permission.cancel") as string,
      type: "warning",
    })
      .then(async () => {
        //显示等待
        this.listLoading = true;

        //删除博主
        await removeCustomerTagUser({ tag_id: row.tag_id, user_ids: [row.user_id] });

        //获取列表
        this.getList();
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //---------------------------- 添加/删除客服 ----------------------------
  //定义变量
  private tags: any[] = [];
  private tagInput: string = "";
  private tagInputMore: string = "";
  private dialogStatus: string = ""; //对话框状态
  private dialogVisible: boolean = false; //是否显示
  private dialogLoading: boolean = false; //对话框等待
  private dialogTitleMap: any = { add: "添加客服用户", delete: "删除客服用户" }; //对话框标题

  //表单数据
  private addForm: any = {
    //请求数据
    tag_id: 0, //客服标签ID
    user_ids: [], //用户ID列表
  };

  //是否添加
  private isAdd(): boolean {
    return this.dialogStatus === "add";
  }

  //处理添加
  private async addData() {
    //异常处理
    if (this.addForm.tag_id == 0) return Message({ type: "error", duration: 5 * 1000, message: "请选择客服标签" });
    if (this.tags.length == 0) return Message({ type: "error", duration: 5 * 1000, message: "请添加用户ID" });

    //数据赋值
    this.addForm.user_ids = [];
    for (var i: number = 0; i < this.tags.length; i++) {
      this.addForm.user_ids.push(Number(this.tags[i]));
    }

    //添加用户
    await saveCustomerTagUser(this.addForm);

    //隐藏界面
    this.dialogVisible = false;

    //显示列表
    this.getList();
  }

  //处理删除
  private async deleteData() {
    //异常处理
    if (this.addForm.tag_id == 0) return Message({ type: "error", duration: 5 * 1000, message: "请选择客服标签" });
    if (this.tags.length == 0) return Message({ type: "error", duration: 5 * 1000, message: "请添加用户ID" });

    //数据赋值
    this.addForm.user_ids = [];
    for (var i: number = 0; i < this.tags.length; i++) {
      this.addForm.user_ids.push(Number(this.tags[i]));
    }

    //添加用户
    await removeCustomerTagUser(this.addForm);

    //隐藏界面
    this.dialogVisible = false;

    //显示列表
    this.getList();
  }

  //添加ID
  private addTag(type: any): void {
    //定义变量
    let errorList: any = [];

    //批量添加
    if (type === "more") {
      this.tagInputMore
        .trim()
        .split(",")
        .forEach((item: any) => {
          if (!/^[1-9]\d*$/.test(item.trim())) return errorList.push(item.trim());

          //数据赋值
          this.tags.push(item.trim());

          //清空输入框
          this.tagInputMore = "";
        });

      //返回错误
      if (errorList.length) this.$message.error(`${errorList.join(",")}不属于正确格式，请输入数字并以英文格式的逗号进行分隔`);
    } else {
      if (/^[1-9]\d*$/.test(this.tagInput.trim()) && this.tagInput.trim() !== "") {
        //数据赋值
        this.tags.push(this.tagInput.trim());

        //清空输入框
        this.tagInput = "";
      } else {
        //显示提示
        this.$message.error("请输入正确形式的ID");
        //清空输入框
        this.tagInput = "";
      }
    }
  }

  //移除ID
  private removeTag(index: any): void {
    //数据赋值
    this.tags.splice(index, 1);
  }
}
